import Vue from 'vue';
import moment from 'moment';

Vue.filter('textEllipsis', (value, maxLength) => {
  if (!value) return '';
  if (value.length > maxLength) {
    return `${value.substring(0, maxLength)}...`;
  }
  return value;
});

Vue.filter(
  'numberFormatter',
  (value, maximumFractionDigits = 2, minimumFractionDigits) => {
    if (!value) return '0';
    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits,
      minimumFractionDigits,
    });
    return formatter.format(value);
  },
);

Vue.filter('dateFormatter', (value, format) => {
  if (!value) return '';
  return moment(value).format(format);
});
