import moment from 'moment';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('appStore', {
      appState: state => state,
    }),
    accountData() {
      return this.appState.accountData;
    },
    dateRange: {
      get() {
        return this.appState.dateRange;
      },
      set(newDateRange) {
        this.$store.dispatch('appStore/setDateRange', newDateRange);
      },
    },
    fullDateRange() {
      const dateRange = [...this.dateRange.sort()];
      if (!dateRange[1]) {
        dateRange.push(dateRange[0]);
      }
      return [
        moment(dateRange[0], 'YYYY-MM')
          .startOf('month')
          .toDate(),
        moment(dateRange[1], 'YYYY-MM')
          .endOf('month')
          .toDate(),
      ];
    },
    chartDateRange() {
      const dates = [];
      const startDate = moment(this.dateRange[0], 'YYYY-MM').startOf('month');
      const endDate = moment(this.dateRange[1], 'YYYY-MM').endOf('month');
      do {
        dates.push(startDate.format('YYYY-MM-DD'));
      } while (startDate.add(1, 'days').diff(endDate) < 0);
      return dates;
    },
    defaultProductImageUrl() {
      return 'https://cdn.shopify.com/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c.gif';
    },
  },
};
