import createApp from '@shopify/app-bridge';
import { Redirect } from '@shopify/app-bridge/actions';
import Vue from 'vue';
import VuePerfectScrollbar from 'vue2-perfect-scrollbar';
import queryString from 'query-string';

import './config';
import './filters';
import './services/ApiService';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import AppMixin from './mixins/AppMixin';

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

window.shopifyData = queryString.parse(window.location.search);
// window.shopifyData = { shop: 'cta-dev.myshopify.com' };
const { host, shop: shopOrigin } = window.shopifyData;

const apiKey = process.env.VUE_APP_SHOPIFY_API_KEY;
const redirectUri = `${process.env.VUE_APP_API_BASE_URL}/web/shopify/connect`;
const scopes = process.env.VUE_APP_SHOPIFY_SCOPES;
const permissionUrl = `https://${shopOrigin}/admin/oauth/authorize?client_id=${apiKey}&scope=${scopes}&redirect_uri=${redirectUri}`;

if (window.top === window.self) {
  window.location.assign(permissionUrl);
} else {
  const app = createApp({
    apiKey,
    host,
  });

  app.subscribe(Redirect.Action.APP, ({ path }) => {
    router.push(path);
  });

  Vue.config.productionTip = false;

  Vue.use(VuePerfectScrollbar);

  Vue.mixin(AppMixin);

  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app');
}
