import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "main-layout" */ '@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        redirect: 'dashboard',
      },
      {
        path: 'dashboard',
        name: 'dashboard-page',
        component: () => import(/* webpackChunkName: "dashboard-page" */ '@/pages/DashboardPage.vue'),
      },
      {
        path: 'sale-overview',
        name: 'sale-overview-page',
        component: () => import(/* webpackChunkName: "sale-overview-page" */ '@/pages/SaleOverviewPage.vue'),
      },
      {
        path: 'products',
        name: 'products-page',
        component: () => import(/* webpackChunkName: "products-page" */ '@/pages/ProductsPage.vue'),
      },
      {
        path: 'buyer-profile',
        name: 'buyer-profile-page',
        component: () => import(/* webpackChunkName: "buyer-profile-page" */ '@/pages/BuyerProfilePage.vue'),
      },
      {
        path: 'visitor-profile',
        name: 'visitor-profile-page',
        component: () => import(/* webpackChunkName: "visitor-profile-page" */ '@/pages/VisitorProfilePage.vue'),
      },
      {
        path: 'channel-profile',
        name: 'channel-profile-page',
        component: () => import(/* webpackChunkName: "channel-profile-page" */ '@/pages/ChannelProfilePage.vue'),
      },
      {
        path: 'reports',
        name: 'reports-page',
        component: () => import(/* webpackChunkName: "reports-page" */ '@/pages/ReportsPage.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
