import axios from 'axios';

const http = axios.create({
  baseURL: $config.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.defaults.timeout = 10000;
http.defaults.params = {};

http.interceptors.request.use(
  reqConfig => {
    const shopifyData = Object.keys(window.shopifyData).reduce((mapped, key) => {
      mapped[`__${key}`] = window.shopifyData[key];
      return mapped;
    }, {});
    reqConfig.params = {
      ...reqConfig.params,
      ...shopifyData,
    };
    return reqConfig;
  },
  err => Promise.reject(err),
);

http.interceptors.response.use(
  ({ data }) => data,
  ({ response }) => Promise.reject(response),
);

window.$http = http;

export default http;
