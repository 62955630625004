import moment from 'moment';

const initialState = {
  accountData: null,
  isMenuSidebarOpen: false,
  dateRange: [
    moment()
      .startOf('month')
      .format('YYYY-MM'),
    moment()
      .endOf('month')
      .format('YYYY-MM'),
  ],
};

const mutations = {
  setAccountData(state, payload) {
    state.accountData = payload;
  },
  setMenuSidebarOpen(state, payload) {
    state.isMenuSidebarOpen = payload;
  },
  setDateRange(state, payload) {
    state.dateRange = payload;
  },
};

const actions = {
  setAccountData(context, payload) {
    context.commit('setAccountData', payload);
  },
  setMenuSidebarOpen(context, payload) {
    context.commit('setMenuSidebarOpen', payload);
  },
  setDateRange(context, payload) {
    context.commit('setDateRange', payload);
  },
};

const getters = {
  //
};

export default {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters,
};
